// modules
import React from 'react'
// components
import SEO from '../components/shared/SEO/SEO'

// definitions
function PageNotFound404() {
  const pageTitle = 'Page not found'
  return (
    <>
      <SEO title={pageTitle} />
      <h1>{pageTitle}</h1>
    </>
  )
}

// exports
export default PageNotFound404
